import dayjs from 'dayjs';
import { makeObservable, observable, action, computed, runInAction } from 'mobx';
import { message } from 'antd';

import i18n from 'src/i18n';
import { QUEUE_SCHEDULE_TYPE, TIME_RANGE, SOURCE_CATEGORY_TC, TOPIC_ATTRIBUTE_TYPE } from 'src/constants/normal';

import ProjectService from 'src/services/project';
import EDMService from 'src/services/edm';
import ResultService from 'src/services/result';
import mainStore from 'src/stores/mainStore';

export default class DirectClientMarketingResultPageViewModel {
  projectId = '';
  newsletterId = '';
  resultId = '';
  startDate = '';
  endDate = '';
  edmType = 'edm';

  @observable newsletterName = '電子報主題';
  @observable timeText = '';
  @observable tagPool = [];
  @observable subjectList = [];
  @observable selectedSubject = null;
  @observable mainBrand = {};
  @observable mainBrandWOMCount = 0;
  @observable mainBrandChannels = [];
  @observable mainBrandSentiments = [];
  @observable mainBrandFeatures = [];
  @observable manualStatistics = [];

  @observable searchAngle = 'brand';

  @observable isSummaryCalculate = false;

  // ? 2024/11/01 新增auto summary選項，所以需要狀態來判斷是否顯示重新產生自動摘要Btn
  @observable autoSummary = false;
  @observable isMemoUpdating = false;
  @observable memo = '';
  memoCache = '';

  @observable isShowRefreshAutoSummaryModal = false;
  refreshAutoSummaryResolve = null;

  @observable topics = [];
  searchId = null;
  @observable hasNextPage = false;

  // > resize related
  resizeObserver = null;
  @observable width = 1920;

  @computed get ratingTags() {
    return this.tagPool.filter((tag) => tag.type === 'rating');
  }

  @computed get availableRatingTags() {
    const result = {};
    this.ratingTags.forEach((tag) => {
      if (!tag.deletedAt) {
        result[`${tag.value}`] = tag.label;
      }
    });
    return result;
  }

  @computed get ratingTagsObject() {
    const tagsMap = new Map(this.ratingTags.map((item) => [item.value, item.label]));
    return Object.fromEntries(tagsMap);
  }

  @computed get topicTags() {
    return this.tagPool.filter((tag) => tag.type === 'topic');
  }

  @computed get suggestionTags() {
    return this.tagPool.filter((tag) => tag.type === 'suggestion');
  }

  @computed get departmentTags() {
    return this.tagPool.filter((tag) => tag.type === 'department');
  }

  @computed get selectedSubjectContent() {
    return this.subjectList.find((subject) => subject.value === this.selectedSubject);
  }

  @computed get currentPage() {
    return Math.ceil(this.topics.length / 20);
  }

  @computed get ratingTagsIndex() {
    return this.ratingTags.map((item) => item.value);
  }

  constructor() {
    makeObservable(this);
  }

  @action didMount = async (params) => {
    this.registerObserver();
    const {
      id,
      rid
    } = params;

    if (this.newsletterId) {
      return;
    }

    this.newsletterId = id;
    this.resultId = rid;

    await this.getNewsletterDetail();

    await Promise.all([
      this.updateSearchAngle(),
      this.getResultDetail()
    ]);

    await Promise.all([
      this.getResultSummary(),
      this.getResultTopicsById()
    ]);
  };

  @action registerObserver = () => {
    const resizeObserver = new ResizeObserver((entries) => {
      const { width } = entries[0].contentRect;
      runInAction(() => {
        this.width = width;
      });
    });
    this.resizeObserver = resizeObserver;
    const container = document.querySelector('#app');
    this.resizeObserver.observe(container);
  };

  @action willUnmount = () => {
    const container = document.querySelector('#app');
    this.resizeObserver.unobserve(container);
  };

  @action getNewsletterDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('newsletterDetail')) {
        return;
      }
      mainStore.setLoading('newsletterDetail');

      const { projectId, name, tags, autoSummary } = await EDMService.getEDMDetail(this.newsletterId);
      runInAction(() => {
        this.projectId = projectId;
        this.newsletterName = name;
        this.tagPool = tags.map((tag) => ({ value: tag.id, label: tag.value, type: tag.type, deletedAt: tag.deletedAt, className: tag.deletedAt ? 'hideOption' : 'option' }));
        this.autoSummary = autoSummary;
      });
    } catch (error) {
      message.error(i18n.t('api_get_edm_detail_error'));
      this.basicDataInit = false;
    } finally {
      mainStore.setLoadingComplete('newsletterDetail');
    }
  };

  @action getResultDetail = async () => {
    try {
      if (mainStore.checkIsInQueue('resultDetail')) {
        return;
      }
      mainStore.setLoading('resultDetail');

      const { startDate, endDate, type, memo, topics, mainBrandId } = await ResultService.getResultDetail(this.newsletterId, this.resultId);
      const subjectList = await Promise.all([
        ...topics.map((item) => this.updateSubjectContent(item))
      ]);

      runInAction(() => {
        this.startDate = startDate;
        this.endDate = endDate;
        this.timeText = [QUEUE_SCHEDULE_TYPE.Daily, QUEUE_SCHEDULE_TYPE.Evening, QUEUE_SCHEDULE_TYPE.Noon, QUEUE_SCHEDULE_TYPE.Night].includes(type)
          ? `${dayjs(startDate).tz('Asia/Taipei').format('YYYY/MM/DD')} ${TIME_RANGE[type]}`
          : `${dayjs(startDate).tz('Asia/Taipei').format('YYYY/MM/DD')} ${TIME_RANGE[type]} - ${dayjs(endDate).tz('Asia/Taipei').format('YYYY/MM/DD')} ${TIME_RANGE[type]}`;
        this.memo = memo ?? '';
        this.memoCache = memo ?? '';
        this.subjectList = subjectList;
        this.selectedSubject = this.subjectList[0].value;
        this.mainBrand.id = mainBrandId;
      });
      await this.updateMainBrand();
    } catch (error) {
      message.error(i18n.t('api_get_edm_result_detail_error'));
      this.basicDataInit = false;
    } finally {
      mainStore.setLoadingComplete('resultDetail');
    }
  };

  @action getResultSummary = async () => {
    if (this.isSummaryCalculate) {
      return;
    }
    this.isSummaryCalculate = true;
    try {
      const {
        channel,
        feature,
        sentiment,
        subject,
        womCount
      } = await ResultService.getResultSummary(this.newsletterId, { gte: this.startDate, lte: this.endDate });

      runInAction(() => {
        this.mainBrandChannels = channel.slice().sort((a, b) => b.count - a.count);
        this.mainBrandWOMCount = womCount;
        this.mainBrandSentiments = [
          {
            key: 'positive',
            name: i18n.t('common_positive'),
            count: sentiment?.positiveCount ?? 0
          },
          {
            key: 'negative',
            name: i18n.t('common_negative'),
            count: sentiment?.negativeCount ?? 0
          },
          {
            key: 'neutral',
            name: i18n.t('common_neutral'),
            count: sentiment?.neutralCount ?? 0
          }
        ];
        this.mainBrandFeatures = feature;
        this.manualStatistics = subject.filter((sub) => this.subjectList.find((item) => item.value === sub.id && item.hasEvaluation)).map((sub) => ({
          id: sub.id,
          name: this.subjectList.find((item) => item.value === sub.id).label,
          rating: sub.rating.filter((r) => this.availableRatingTags[r.id]).sort((a, b) => this.ratingTagsIndex.indexOf(a.id) - this.ratingTagsIndex.indexOf(b.id)).map((r) => ({
            id: r.id,
            name: this.availableRatingTags[r.id] ?? '無',
            count: r.count
          }))
        }));
      });

    } catch (error) {
      message.error(i18n.t('api_get_manual_summary_error'));
    } finally {
      runInAction(() => {
        this.isSummaryCalculate = false;
      });
    }
  };

  @action onRefreshSummary = async () => {
    if (this.isSummaryCalculate) {
      return;
    }
    this.isSummaryCalculate = true;
    try {

      const {
        subject
      } = await ResultService.getResultSummary(this.newsletterId, { gte: this.startDate, lte: this.endDate });

      runInAction(() => {
        this.manualStatistics = subject.filter((sub) => this.subjectList.find((item) => item.value === sub.id && item.hasEvaluation)).map((sub) => ({
          id: sub.id,
          name: this.subjectList.find((item) => item.value === sub.id).label,
          rating: sub.rating.filter((r) => this.availableRatingTags[r.id]).sort((a, b) => this.ratingTagsIndex.indexOf(a.id) - this.ratingTagsIndex.indexOf(b.id)).map((r) => ({
            id: r.id,
            name: this.availableRatingTags[r.id] ?? '無',
            count: r.count
          }))
        }));
      });

      message.success(i18n.t('result_edit_page_calculate_message_success'));

    } catch (error) {
      message.success(i18n.t('result_edit_page_calculate_message_error'));
    } finally {
      runInAction(() => {
        this.isSummaryCalculate = false;
      });
    }
  };

  // > 2024/10/17 added -> refresh auto summary
  @action onRefreshAutoSummary = async () => {
    try {
      if (this.memo.trim()) {
        const result = await this.showRefreshAutoConfirmModal();
        runInAction(() => {
          this.isShowRefreshAutoSummaryModal = false;
          this.refreshAutoSummaryResolve = null;
        });

        if (!result) {
          return;
        }
      }
      if (mainStore.checkIsInQueue('autoSummary')) {
        return;
      }
      mainStore.setLoading('autoSummary');
      const summary = await ResultService.refreshAutoSummary(this.newsletterId, this.resultId);
      console.log('summary', summary);
      if (!summary.trim()) {
        throw new Error('auto summary is empty');
      }
      runInAction(() => {
        this.memo = summary;
      });
      await this.onMemoEditEnd();
    } catch (error) {
      message.error(i18n.t('api_get_auto_summary_error'));
    } finally {
      mainStore.setLoadingComplete('autoSummary');
    }
  };

  @action showRefreshAutoConfirmModal = async () => {
    return new Promise((resolve) => {
      this.isShowRefreshAutoSummaryModal = true;
      this.refreshAutoSummaryResolve = resolve;
    });
  };



  @action getResultTopicsById = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }

      mainStore.setLoading('topics');

      const { list, searchId } = await ResultService.getResultTopics(
        this.newsletterId,
        {
          date: {
            gte: this.startDate,
            lte: this.endDate
          },
          subjectId: this.selectedSubject
        }
      );

      runInAction(() => {
        this.topics = list;
        this.searchId = searchId;
        this.hasNextPage = list.length === 20;
      });
    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };

  @action getPagingTopics = async () => {
    try {
      if (mainStore.checkIsInQueue('topics')) {
        return;
      }

      mainStore.setLoading('topics');
      const { list } = await ResultService.getResultTopicsByPage(this.newsletterId, { page: this.currentPage + 1, searchId: this.searchId, size: 20 });

      runInAction(() => {
        this.topics = [...this.topics, ...list];
        this.hasNextPage = list.length === 20;
      });

    } catch (error) {
      message.error(i18n.t('api_get_topics_error'));
    } finally {
      mainStore.setLoadingComplete('topics');
    }
  };

  @action updateMainBrand = async () => {
    // > deprecated -> newsletter's main brand won't be null.
    // if (!this.mainBrand.id) {
    //   return;
    // }
    try {
      if (mainStore.checkIsInQueue('mainBrand')) {
        return;
      }
      mainStore.setLoading('mainBrand');
      const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [this.mainBrand.id]);
      runInAction(() => {
        this.mainBrand.name = brand.name;
      });
    } catch (error) {
      message.error(i18n.t('api_level_one_not_found_error'));
    } finally {
      mainStore.setLoadingComplete('mainBrand');
    }
  };

  @action updateSubjectContent = async (item) => {
    const {
      id,
      hasEvaluation,
      searchKeywordLevel1Id,
      searchKeywordLevel2Id,
      searchKeywordLevel3Id,
      searchTagParentId,
      searchTagId,
      category
    } = item;

    try {
      const sourceText = !category[0]
        ? i18n.t('edm_drawer_topic_source_all')
        : `${SOURCE_CATEGORY_TC[category[0]]}`;

      let label = '';

      if (searchKeywordLevel1Id && searchKeywordLevel2Id) {
        const [brand, productLine] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel2Id]);
        label = `${brand.name}/${productLine.name}-${sourceText}`;
      }

      if (searchKeywordLevel1Id && searchKeywordLevel3Id) {
        const [brand, product] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id, searchKeywordLevel3Id]);
        label = `${brand.name}/${product.name}-${sourceText}`;
      }

      if (searchKeywordLevel1Id && !searchKeywordLevel2Id && !searchKeywordLevel3Id) {
        const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
        label = `${brand.name}-${sourceText}`;
      }

      if (searchTagParentId) {
        const [tagParent, tag] = await ProjectService.getTagContentByIds(this.projectId, [searchTagParentId, searchTagId]);
        if (searchKeywordLevel1Id) {
          const [brand] = await ProjectService.getLevelContentByIds(this.projectId, [searchKeywordLevel1Id]);
          label = `${brand.name}/${tagParent.name}/${tag.name}-${sourceText}`;
        } else {
          label = `${tagParent.name}/${tag.name}-${sourceText}`;
        }
      }

      return {
        value: id,
        label,
        hasEvaluation
      };

    } catch (error) {

      return {
        value: id,
        label: i18n.t('common_error_subject_title'),
        hasEvaluation
      };

    }
  };

  @action updateSearchAngle = async () => {
    try {
      if (mainStore.checkIsInQueue('searchAngle')) {
        return;
      }
      mainStore.setLoading('searchAngle');

      const { meta: { searchAngle } } = await ProjectService.getProjectDetailById(this.projectId);

      runInAction(() => {
        this.searchAngle = searchAngle;
      });

    } catch (error) {
      message.error(i18n.t('api_get_project_angle_error'));
    } finally {
      mainStore.setLoadingComplete('searchAngle');
    }
  };

  @action onMemoChange = (event) => {
    this.memo = event.target.value;
  };

  @action onMemoEditEnd = async () => {
    if (this.isMemoUpdating || this.memo === this.memoCache) {
      return;
    }
    this.isMemoUpdating = true;
    try {
      if (mainStore.checkIsInQueue('memoUpdating')) {
        return;
      }
      mainStore.setLoading('memoUpdating');
      await ResultService.updateResultDetail(this.newsletterId, this.resultId, this.memo);
      message.success(i18n.t('result_edit_page_memo_update_success'));
      runInAction(() => {
        this.memoCache = this.memo;
      });
    } catch (error) {
      message.error(i18n.t('api_post_memo_error'));
    } finally {
      runInAction(() => {
        this.isMemoUpdating = false;
      });
      mainStore.setLoadingComplete('memoUpdating');
    }

  };

  @action onSubjectSelect = async (value) => {
    this.selectedSubject = value;

    this.topics = [];
    this.searchId = null;
    this.page = 1;

    await this.getResultTopicsById();
  };

  @action onEvalTypeChange = async (value, recordId, lv1Id) => {
    try {
      const target = this.topics.find((topic) => topic.id === recordId);
      if (!target) {
        return;
      }

      await ResultService.updateTopicAttribute(this.newsletterId, {
        subjectId: this.selectedSubject,
        mainId: recordId,
        lv1Id,
        attrType: TOPIC_ATTRIBUTE_TYPE.Rating,
        attrValue: value
      });

      runInAction(() => {
        target.evalType = value;
      });
    } catch (error) {
      message.error(i18n.t('api_put_update_eval_type_error'));
    }
  };

  @action onTopicAttrChange = async (value, recordId, lv1Id) => {
    try {
      const target = this.topics.find((topic) => topic.id === recordId);
      if (!target) {
        return;
      }

      await ResultService.updateTopicAttribute(this.newsletterId, {
        subjectId: this.selectedSubject,
        mainId: recordId,
        lv1Id,
        attrType: TOPIC_ATTRIBUTE_TYPE.Topic,
        attrValue: value
      });

      runInAction(() => {
        target.topicAttr = value;
      });
    } catch (error) {
      message.error(i18n.t('api_put_update_topic_attribute_error'));
    }
  };

  @action onReportAdviceChange = async (value, recordId, lv1Id) => {
    try {
      const target = this.topics.find((topic) => topic.id === recordId);
      if (!target) {
        return;
      }

      await ResultService.updateTopicAttribute(this.newsletterId, {
        subjectId: this.selectedSubject,
        mainId: recordId,
        lv1Id,
        attrType: TOPIC_ATTRIBUTE_TYPE.Suggestion,
        attrValue: value
      });

      runInAction(() => {
        target.reportAdvice = value;
      });
    } catch (error) {
      message.error(i18n.t('api_put_update_report_advice_error'));
    }
  };

  @action onDepartmentAttrChange = async (value, recordId, lv1Id) => {
    try {
      const target = this.topics.find((topic) => topic.id === recordId);
      if (!target) {
        return;
      }

      await ResultService.updateTopicAttribute(this.newsletterId, {
        subjectId: this.selectedSubject,
        mainId: recordId,
        lv1Id,
        attrType: TOPIC_ATTRIBUTE_TYPE.Department,
        attrValue: value
      });

      runInAction(() => {
        target.departmentAttr = value;
      });
    } catch (error) {
      message.error(i18n.t('api_put_update_department_attribute_error'));
    }

  };

  @action onShouldSendChange = async (boolean, recordId, lv1Id) => {
    try {
      const target = this.topics.find((topic) => topic.id === recordId);
      if (!target) {
        return;
      }

      await ResultService.updateTopicAttribute(this.newsletterId, {
        subjectId: this.selectedSubject,
        mainId: recordId,
        lv1Id,
        attrType: TOPIC_ATTRIBUTE_TYPE.ShouldSend,
        attrValue: boolean
      });

      runInAction(() => {
        target.shouldSend = boolean;
      });
    } catch (error) {
      message.error(i18n.t('api_put_update_should_send_error'));
    }
  };
}
