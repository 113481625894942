import i18n from 'src/i18n';

export const AUTH_TOKEN_KEY = 'token';

export const NUMBER_TC = ['一', '二', '三', '四', '五', '六', '七', '八', '九', '十'];

export const AUTO_SEND_TYPE = {
  autoSend: i18n.t('select_auto_send'),
  notAutoSend: i18n.t('select_not_auto')
};

export const AUTO_SEND_OPTIONS = Object.keys(AUTO_SEND_TYPE).map((el) => ({
  value: el === 'autoSend',
  label: AUTO_SEND_TYPE[el]
}));

export const EDM_TEMPLATE_TYPE = {
  EDM: 'edm'
};

export const EDM_TEMPLATE_OPTIONS = [
  {
    value: EDM_TEMPLATE_TYPE.EDM,
    label: i18n.t('edm_drawer_template_direct')
  }
];

export const SEND_FREQUENCY_TYPE = {
  monthly: i18n.t('select_frequency_monthly'),
  weekly: i18n.t('select_frequency_weekly'),
  daily: i18n.t('select_frequency_daily'),
  multiple: i18n.t('select_frequency_today')
};

export const SEND_FREQUENCY_OPTIONS = Object.keys(SEND_FREQUENCY_TYPE).map((el) => ({
  value: el,
  label: SEND_FREQUENCY_TYPE[el]
}));

export const LOG_STAGE = {
  WAITING: 'WAITING',
  BEGIN: 'BEGIN',
  CANCEL: 'CANCEL',
  FAIL: 'FAIL',
  DONE: 'DONE'
};

export const TOPIC_ATTRIBUTE_TYPE = {
  Rating: 'rating',
  Topic: 'topic',
  Suggestion: 'suggestion',
  Department: 'department',
  ShouldSend: 'shouldSend'
};

export const TOPIC_ATTRIBUTE_TYPE_TC = {
  [`${TOPIC_ATTRIBUTE_TYPE.Rating}`]: i18n.t('edm_drawer_setting_topic_sentiment_title'),
  [`${TOPIC_ATTRIBUTE_TYPE.Topic}`]: i18n.t('edm_drawer_setting_topic_attribute_title'),
  [`${TOPIC_ATTRIBUTE_TYPE.Suggestion}`]: i18n.t('edm_drawer_setting_topic_reply_title'),
  [`${TOPIC_ATTRIBUTE_TYPE.Department}`]: i18n.t('edm_drawer_setting_topic_department_title')
};

export const TAG_ACTIONS = {
  Create: 'create',
  Update: 'update',
  Delete: 'delete'
};

export const DEFAULT_SENTIMENT = {
  Positive: 'positive',
  Negative: 'negative',
  Neutral: 'neutral'
};

export const DEFAULT_SENTIMENT_OPTIONS = [
  {
    value: DEFAULT_SENTIMENT.Positive,
    label: i18n.t('edm_drawer_setting_topic_sentiment_positive')
  },
  {
    value: DEFAULT_SENTIMENT.Neutral,
    label: i18n.t('edm_drawer_setting_topic_sentiment_neutral')
  },
  {
    value: DEFAULT_SENTIMENT.Negative,
    label: i18n.t('edm_drawer_setting_topic_sentiment_negative')
  }
];

export const DEFAULT_SENTIMENT_TC = [
  i18n.t('edm_drawer_setting_topic_sentiment_negative'),
  i18n.t('edm_drawer_setting_topic_sentiment_neutral'),
  i18n.t('edm_drawer_setting_topic_sentiment_positive')
];

export const DEFAULT_TOPIC_ATTRIBUTE = {
  Recommend: 'recommend',
  Gossip: 'gossip',
  BrandImage: 'brandImage',
  BrandCompare: 'brandCompare',
  ProductCompare: 'productCompare',
  ProductExperience: 'productExperience',
  Coupon: 'coupon',
  PlaceRelated: 'placeRelated',
  PriceRelated: 'priceRelated',
  ServiceRelated: 'serviceRelated',
  Others: 'others'
};

export const DEFAULT_TOPIC_ATTRIBUTE_OPTIONS = [
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.Recommend,
    label: i18n.t('edm_drawer_setting_topic_attribute_recommend')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.Gossip,
    label: i18n.t('edm_drawer_setting_topic_attribute_gossip')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.BrandImage,
    label: i18n.t('edm_drawer_setting_topic_attribute_brand_image')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.BrandCompare,
    label: i18n.t('edm_drawer_setting_topic_attribute_brand_compare')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.ProductCompare,
    label: i18n.t('edm_drawer_setting_topic_attribute_product_compare')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.ProductExperience,
    label: i18n.t('edm_drawer_setting_topic_attribute_product_experience')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.Coupon,
    label: i18n.t('edm_drawer_setting_topic_attribute_coupon')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.PlaceRelated,
    label: i18n.t('edm_drawer_setting_topic_attribute_place_related')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.PriceRelated,
    label: i18n.t('edm_drawer_setting_topic_attribute_price_related')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.ServiceRelated,
    label: i18n.t('edm_drawer_setting_topic_attribute_service_related')
  },
  {
    value: DEFAULT_TOPIC_ATTRIBUTE.Others,
    label: i18n.t('edm_drawer_setting_topic_reply_others')
  }
];

export const DEFAULT_REPLY = {
  Need: 'need',
  Suppose: 'suppose',
  NoNeed: 'noNeed',
  KeepWatching: 'keepWatching',
  Others: 'others'
};

export const DEFAULT_REPLY_OPTIONS = [
  {
    value: DEFAULT_REPLY.Need,
    label: i18n.t('edm_drawer_setting_topic_reply_need')
  },
  {
    value: DEFAULT_REPLY.Suppose,
    label: i18n.t('edm_drawer_setting_topic_reply_suppose')
  },
  {
    value: DEFAULT_REPLY.NoNeed,
    label: i18n.t('edm_drawer_setting_topic_reply_no_need')
  },
  {
    value: DEFAULT_REPLY.KeepWatching,
    label: i18n.t('edm_drawer_setting_topic_reply_keep_watching')
  },
  {
    value: DEFAULT_REPLY.Others,
    label: i18n.t('edm_drawer_setting_topic_reply_others')
  }
];

export const DEFAULT_DEPARTMENT = {
  Brand: 'brand',
  Product: 'product',
  PR: 'PR',
  Marketing: 'marketing'
};

export const DEFAULT_DEPARTMENT_OPTIONS = [
  {
    value: DEFAULT_DEPARTMENT.Brand,
    label: i18n.t('edm_drawer_setting_topic_department_brand')
  },
  {
    value: DEFAULT_DEPARTMENT.Product,
    label: i18n.t('edm_drawer_setting_topic_department_product')
  },
  {
    value: DEFAULT_DEPARTMENT.PR,
    label: i18n.t('edm_drawer_setting_topic_department_PR')
  },
  {
    value: DEFAULT_DEPARTMENT.Marketing,
    label: i18n.t('edm_drawer_setting_topic_department_marketing')
  }
];

export const LEVEL_TYPE = {
  Brand: 'brand',
  ProductLine: 'productLine',
  Product: 'product',
  Tag: 'tag'
};

export const LEVEL_OPTIONS = [
  {
    value: LEVEL_TYPE.Brand,
    label: i18n.t('common_brand')
  },
  {
    value: LEVEL_TYPE.ProductLine,
    label: i18n.t('common_product_line')
  },
  {
    value: LEVEL_TYPE.Product,
    label: i18n.t('common_product')
  },
  {
    value: LEVEL_TYPE.Tag,
    label: i18n.t('common_tag')
  }
];

export const LEVEL_OPTIONS_CATEGORY_ANGLE = [
  {
    value: LEVEL_TYPE.Brand,
    label: i18n.t('common_brand_category')
  },
  {
    value: LEVEL_TYPE.ProductLine,
    label: i18n.t('common_brand')
  },
  {
    value: LEVEL_TYPE.Product,
    label: i18n.t('common_product')
  },
  {
    value: LEVEL_TYPE.Tag,
    label: i18n.t('common_tag')
  }
];

export const SOURCE_TYPE = {
  Category: 'category',
  Website: 'website',
  Channel: 'channel'
};

export const SOURCE_TYPE_OPTIONS = [
  {
    value: SOURCE_TYPE.Category,
    label: i18n.t('edm_setting_modal_topic_setting_category')
  },
  {
    value: SOURCE_TYPE.Website,
    label: i18n.t('edm_setting_modal_topic_setting_website')
  },
  {
    value: SOURCE_TYPE.Channel,
    label: i18n.t('edm_setting_modal_topic_setting_channel')
  }
];

export const SOURCE_CATEGORY_TYPE = {
  News: 'news',
  Forum: 'forum',
  Facebook: 'facebook',
  IG: 'ig',
  Threads: 'threads',
  Video: 'video',
  Others: 'others'
};

export const SOURCE_CATEGORY_OPTIONS = [
  {
    // > 新聞
    value: SOURCE_CATEGORY_TYPE.News,
    label: i18n.t('common_source_category_news')
  },
  {
    // > 論壇
    value: SOURCE_CATEGORY_TYPE.Forum,
    label: i18n.t('common_source_category_forum')
  },
  {
    // > FB
    value: SOURCE_CATEGORY_TYPE.Facebook,
    label: i18n.t('common_source_category_facebook')
  },
  {
    // > ig
    value: SOURCE_CATEGORY_TYPE.IG,
    label: i18n.t('common_source_category_instagram')
  },
  {
    // > thread
    value: SOURCE_CATEGORY_TYPE.Threads,
    label: i18n.t('common_source_category_thread')
  },
  {
    // > Youtube
    value: SOURCE_CATEGORY_TYPE.Video,
    label: i18n.t('common_source_category_video')
  },
  {
    // > others
    value: SOURCE_CATEGORY_TYPE.Others,
    label: i18n.t('common_source_category_others')
  }
];

export const SOURCE_CATEGORY_TC = {
  [`${SOURCE_CATEGORY_TYPE.News}`]: i18n.t('common_source_category_news'),
  [`${SOURCE_CATEGORY_TYPE.Forum}`]: i18n.t('common_source_category_forum'),
  [`${SOURCE_CATEGORY_TYPE.Facebook}`]: i18n.t('common_source_category_facebook'),
  [`${SOURCE_CATEGORY_TYPE.IG}`]: i18n.t('common_source_category_instagram'),
  [`${SOURCE_CATEGORY_TYPE.Threads}`]: i18n.t('common_source_category_thread'),
  [`${SOURCE_CATEGORY_TYPE.Video}`]: i18n.t('common_source_category_video'),
  [`${SOURCE_CATEGORY_TYPE.Others}`]: i18n.t('common_source_category_others')
};


export const TIME_SELECT_MODAL_TYPE = {
  ReAnalysis: 'reAnalysis',
  Download: 'download'
};

export const QUEUE_SCHEDULE_TYPE = {
  Daily: 'daily',
  Weekly: 'weekly',
  Monthly: 'monthly',
  Noon: 'noon',
  Evening: 'evening',
  Night: 'night'
};

export const QUEUE_SCHEDULE_OPTIONS = [
  {
    value: QUEUE_SCHEDULE_TYPE.Monthly,
    label: i18n.t('select_frequency_monthly')
  },
  {
    value: QUEUE_SCHEDULE_TYPE.Weekly,
    label: i18n.t('select_frequency_weekly')
  },
  {
    value: QUEUE_SCHEDULE_TYPE.Daily,
    label: i18n.t('select_frequency_daily')
  },
  {
    value: QUEUE_SCHEDULE_TYPE.Noon,
    label: i18n.t('select_frequency_noon')
  },
  {
    value: QUEUE_SCHEDULE_TYPE.Evening,
    label: i18n.t('select_frequency_evening')
  },
  {
    value: QUEUE_SCHEDULE_TYPE.Night,
    label: i18n.t('select_frequency_night')
  }
];

export const TIME_RANGE = {
  [`${QUEUE_SCHEDULE_TYPE.Daily}`]: '(00:00-23:59)',
  [`${QUEUE_SCHEDULE_TYPE.Weekly}`]: '(00:00-23:59)',
  [`${QUEUE_SCHEDULE_TYPE.Monthly}`]: '(00:00-23:59)',
  [`${QUEUE_SCHEDULE_TYPE.Noon}`]: '(00:00-10:00)',
  [`${QUEUE_SCHEDULE_TYPE.Evening}`]: '(00:00-16:00)',
  [`${QUEUE_SCHEDULE_TYPE.Night}`]: '(00:00-20:00)'
};

export const NEWSLETTER_STATUS_TYPE = {
  Waiting: 'WAITING',
  Begin: 'BEGIN',
  Done: 'DONE',
  Fail: 'FAIL',
  Cancel: 'CANCEL'
};

export const NEWSLETTER_STATUS_OPTIONS = [
  {
    value: NEWSLETTER_STATUS_TYPE.Waiting,
    label: i18n.t('select_status_waiting')
  },
  {
    value: NEWSLETTER_STATUS_TYPE.Begin,
    label: i18n.t('select_status_begin')
  },
  {
    value: NEWSLETTER_STATUS_TYPE.Done,
    label: i18n.t('select_status_done')
  },
  {
    value: NEWSLETTER_STATUS_TYPE.Fail,
    label: i18n.t('select_status_fail')
  }
];
